<template>
  <div class="management-info-page model-detail">
    <bread-crumb :name="modelInfo.name" />
    <div class="page-title-row">
      <div class="title">
        {{ $t('sideNav.modelInfo') }}
      </div>
      <button
        v-if="!isLoading"
        type="button"
        class="btn-m btn-secondary"
        @click="isShowDeleteDialog = true"
      >
        {{ $t('model.deleteModel') }}
      </button>
    </div>
    <spinner
      v-if="isLoading"
      :title="$t('editing.loading')"
    />
    <section v-else>
      <div
        :class="{'from-upload': modelInfo.modelGeneratedType === 'UPLOADED'}"
        class="info-block main-info"
      >
        <info-block
          :info="basicInfo"
          title="basic"
          text-area="modelInfo"
          @rename="rename"
        />
        <info-block
          :info="advancedInfo"
          :title="modelInfo.modelGeneratedType === 'TRAINED' ? 'training' : 'file'"
          :link-id="modelInfo.modelTrainingGoalId"
          text-area="modelInfo"
          @showDialog="isShowFileReUploadDialog = $event"
        />
      </div>
      <!-- Output 參數 -->
      <div class="info-block">
        <div class="info-block__title">
          <div>{{ $t('model.outputParameters') }}</div>
          <button
            class="btn btn-outline"
            @click="showDialog('output')"
          >
            {{ $t('model.editContent') }}
          </button>
        </div>
        <div class="info-block__content">
          <div class="table-header">
            <div class="info__label">
              {{ $t('model.parameterDataType') }}
            </div>
            <div class="info__label">
              {{ $t('model.parameterDataName') }}
            </div>
          </div>
          <div
            v-for="(item, index) in modelInfo.ioArgs.output"
            :key="index"
            class="table-row"
          >
            <div class="info__text info__cell">
              {{ item.statsType || 'dataFrame' }}
            </div>
            <div class="info__text info__cell">
              {{ item.modelColumnName || item.modelDataFrameName }}
            </div>
          </div>
        </div>
      </div>
      <!-- Input 參數 -->
      <div class="info-block">
        <div class="info-block__title">
          <div>{{ $t('model.inputParameters') }}</div>
          <button
            v-if="modelInfo.modelGeneratedType === 'UPLOADED'"
            class="btn btn-outline"
            @click="showDialog('input')"
          >
            {{ $t('model.editContent') }}
          </button>
        </div>
        <div class="info-block__content">
          <div class="table-header">
            <div class="info__label">
              {{ $t('model.parameterDataType') }}
            </div>
            <div class="info__label">
              {{ $t('model.parameterDataName') }}
            </div>
          </div>
          <div
            v-for="(item, index) in modelInfo.ioArgs.input"
            :key="index"
            class="table-row"
          >
            <div class="info__text info__cell">
              {{ item.statsType || 'dataFrame' }}
            </div>
            <div class="info__text info__cell">
              {{ item.modelColumnName || item.modelDataFrameName }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <decide-dialog
      v-if="isShowDeleteDialog"
      :content="$t('model.deleteConfirmText')"
      :type="'delete'"
      class="model-delete-dialog"
      @closeDialog="isShowDeleteDialog = false"
      @confirmBtn="deleteModel"
    />
    <params-setting-dialog
      v-if="isShowParamsSettingDialog"
      :model-info="modelInfo"
      :io-type="io"
      @closeDialog="isShowParamsSettingDialog = false"
      @confirmBtn="updateModelInfo"
    />
    <file-re-upload-dialog
      v-if="isShowFileReUploadDialog"
      @closeDialog="isShowFileReUploadDialog = false"
    />
  </div>
</template>
<script>
import BreadCrumb from './components/BreadCrumb'
import DecideDialog from '@/components/dialog/DecideDialog'
import WritingDialog from '@/components/dialog/WritingDialog'
import ParamsSettingDialog from './components/ParamsSettingDialog'
import FileReUploadDialog from './components/FileReUploadDialog'
import InfoBlock from './components/InfoBlock'
import { v4 as uuidv4 } from 'uuid'
import { getModelInfo, deleteModelById, modifyModelInfo } from '@/API/Model'
import { Message } from 'element-ui'

export default {
  name: 'ModelInfo',
  components: {
    BreadCrumb,
    DecideDialog,
    WritingDialog,
    ParamsSettingDialog,
    FileReUploadDialog,
    InfoBlock
  },
  data () {
    return {
      isLoading: false,
      isShowDeleteDialog: false,
      isShowParamsSettingDialog: false,
      isShowFileReUploadDialog: false,
      io: '',
      modelInfo: {}
    }
  },
  created () {
    this.fetchData()
  },
  computed: {
    modelId () {
      return this.$route.params.model_id
    },
    basicInfo () {
      let modelInfo = this.modelInfo
      const modelTrainingInfo = {}
      const basicModelInfo = {
        id: modelInfo.id,
        name: modelInfo.name
      }
      const createdInfo = {
        outputType: modelInfo.modelOrigin === 'UPLOADED_MULTI_DATASET' ? this.$t('model.dataFrameOutput') : this.$t('model.columnOutput'),
        modelGeneratedType: modelInfo.modelGeneratedType === 'TRAINED' ? this.$t('model.modelTraining') : this.$t('editing.userUpload'),
        createdBy: modelInfo.createdBy,
        createdAt: this.timeToDateTime(modelInfo.createdAt)
      }
      if (modelInfo.modelTrainingType) {
        modelTrainingInfo.modelTrainingType = {
          REGRESSION: 'regression',
          BINARY: 'binary'
        }[modelInfo.modelTrainingType]

        if (modelInfo.modelTrainingType === 'BINARY') {
          modelTrainingInfo.primaryTarget = modelInfo.primaryTarget
          modelTrainingInfo.threshold = modelInfo.threshold
        }
      }
      return { ...basicModelInfo, ...modelTrainingInfo, ...createdInfo }
    },
    advancedInfo () {
      let modelInfo = this.modelInfo
      let info
      if (modelInfo.modelGeneratedType === 'TRAINED') {
        info = {
          modelTrainingTaskId: modelInfo.modelTrainingTaskId,
          modelTrainingGoalName: modelInfo.modelTrainingGoalName,
          modelTrainingTaskPath: modelInfo.modelTrainingTaskPath
        }
      } else {
        info = {
          updatedAt: modelInfo.updatedAt ? this.timeToDateTime(modelInfo.updatedAt) : '-',
          fileContent: modelInfo.modelNames?.slice().sort(item => item === this.mainScriptName ? -1 : 0)
        }
      }
      return info
    }
  },
  methods: {
    fetchData () {
      this.isLoading = true
      getModelInfo(this.modelId)
        .then(response => {
          this.modelInfo = {
            ...response,
            ioArgs: response.ioArgs && {
              input: response.ioArgs.input.map(column => {
                return {
                  ...column,
                  id: uuidv4()
                }
              }),
              output: response.ioArgs.output.map(column => {
                return {
                  ...column,
                  id: uuidv4()
                }
              })
            }
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    editModelName () {
      this.isEditing = true
      this.editedName = this.modelInfo.name
    },
    rename (editedName) {
      modifyModelInfo(this.modelId, {
        name: editedName
      })
        .then(() => {
          Message({
            message: this.$t('message.saveSuccess'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
        })
        .finally(() => {
          this.fetchData()
        })
    },
    deleteModel () {
      deleteModelById(this.modelId)
        .then(() => {
          Message({
            message: this.$t('message.deleteSuccess'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
          this.$router.push({ name: 'ModelList' })
        }).finally(() => {
          this.isShowDeleteDialog = false
        })
    },
    showDialog (IO) {
      this.io = IO
      this.isShowParamsSettingDialog = true
    },
    updateModelInfo () {
      this.fetchData()
      this.isShowParamsSettingDialog = false
    }
  }
}
</script>
<style lang="scss" scoped>
.model-detail {
  .info-block {
    &__title {
      display: flex;
      justify-content: space-between;
    }
  }

  ::v-deep .from-upload {
    .info-block {
      &__title {
        height: 36px;
      }
    }
    .info-block {
      &__content {
        height: 86%;
      }
    }
  }
  .main-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
  }
  .model-delete-dialog {
    >>> .dialog-content-wrapper > .content {
      color: #FFF;
    }
  }
}
</style>
