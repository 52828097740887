<template>
  <div class="data-block">
    <div
      v-if="title"
      class="info-block__title"
    >
      {{ $t(`${textArea}.${title}Info`) }}
      <template v-if="title === 'training'">
        <a
          v-if="linkId"
          href="javascript:void(0)"
          class="link action-link"
          @click="gotoTrainingGoal(linkId, info.modelTrainingTaskId)"
        >
          {{ $t('modelInfo.detailTrainingInfo') }}
        </a>
        <el-tooltip
          v-else
          :content="$t('modelInfo.noTrainingInfo')"
        >
          <div class="disabled">
            {{ $t('modelInfo.detailTrainingInfo') }}
          </div>
        </el-tooltip>
      </template>
      <button
        v-else-if="title === 'file'"
        class="btn btn-outline"
        @click="$emit('showDialog', true)"
      >
        {{ $t('fileDataUpdate.reUploadFile') }}
      </button>
    </div>
    <div class="info-block__content">
      <div
        v-for="(row, index) in infoArray"
        :key="`${row}-${index}`"
        class="info__row"
      >
        <div class="info__label">
          {{ $t(`${textArea}.${row}`) }}:
        </div>
        <template v-if="row === 'name'">
          <div
            v-show="!isEditing"
            class="info__text edit-column"
          >
            {{ info[row] }}
            <span
              class="edit-icon"
              @click="editModelName"
            >
              <svg-icon icon-class="edit" />
            </span>
          </div>
          <div
            v-if="isEditing"
            class="edit-block"
          >
            <input-verify
              v-validate="`required`"
              v-model.trim="editedName"
              class="edit-block__input"
              type="text"
              name="tempEditedModelName"
            />
            <div class="edit-block__button">
              <button
                v-if="isDiffName"
                class="btn btn-default"
                @click="save"
              >
                {{ $t('button.save') }}
              </button>
              <button
                class="btn btn-outline"
                @click="isEditing = false"
              >
                {{ $t('button.cancel') }}
              </button>
            </div>
          </div>
        </template>
        <template v-else-if="row === 'modelTrainingType'">
          <div class="info__text">
            {{ $t(`modelInfo.${info[row]}Model`) }}
            <remind-popover
              :content="$t(`model.reminder.${info[row]}Model`)"
              icon-name="description"
              width="240"
            />
          </div>
        </template>
        <template v-else-if="row === 'fileContent'">
          <div class="info__text model-name-area">
            <div
              v-for="(name, index) in info[row]"
              :key="index"
            >
              {{ name }}
            </div>
          </div>
        </template>
        <template v-else>
          <div class="info__text">
            {{ info[row] }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import InputVerify from '@/components/InputVerify'
import RemindPopover from '@/components/popover/RemindPopover.vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'InfoBlock',
  inject: ['$validator'],
  components: {
    InputVerify,
    RemindPopover
  },
  props: {
    title: {
      type: String,
      default: null
    },
    textArea: {
      type: String,
      required: true
    },
    info: {
      type: Object,
      required: true
    },
    linkId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isEditing: false,
      editedName: '',
      mainScriptName: 'main.py'
    }
  },
  computed: {
    ...mapState('modelManagement', ['isComeFromModelInfo', 'trainingTaskId']),
    ...mapGetters('userManagement', ['getCurrentAccountId', 'getCurrentGroupId']),
    infoArray ({ info }) {
      return Object.keys(info)
    },
    isDiffName () {
      return this.editedName !== this.info.name
    }
  },
  methods: {
    ...mapMutations('modelManagement', ['setIsComeFromModelInfo', 'setTrainingTaskId']),
    editModelName () {
      this.isEditing = true
      this.editedName = this.info.name
    },
    save () {
      this.$validator.validateAll().then(isValidated => {
        if (!isValidated) return
        if (!this.isDiffName) return
        this.isEditing = false
        this.$emit('rename', this.editedName)
      })
    },
    gotoTrainingGoal (goal_id, task_id) {
      if (!goal_id) return
      this.setIsComeFromModelInfo(true)
      this.setTrainingTaskId(task_id)
      this.$router.push({
        name: 'TrainingGoal',
        params: {
          account_id: this.getCurrentAccountId,
          group_id: this.getCurrentGroupId,
          goal_id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.data-block {
  .info-block {
    &__title {
      display: flex;
      justify-content: space-between;
      .disabled {
        font-size: 14px;
        opacity: 0.5;
        text-decoration: underline;
        cursor: not-allowed;
        color: #CCCCCC;
      }
    }
    &__content {
      height: 90%;
    }
  }
  .info__label {
    min-width: 120px;
  }
  .info__text.model-name-area {
    width: 100%;
    max-height: 500px;
    overflow: auto;
  }
  .edit-icon {
    position: relative;
    cursor: pointer;
    &:hover {
      color: #DDDDDD;
    }
  }
  .edit-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 10px;
    &__input {
      flex: 1 1;
      margin-right: 20px;
    }
    &__button {
      flex: 2 2;
      .btn {
        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
  ::v-deep .input-verify {
    .input-verify-text {
      margin-bottom: 0
    }
  }
}
</style>
