<template>
  <div class="file-upload-dialog full-page-dialog">
    <div class="dialog-container">
      <div class="dialog-title">
        {{ $t('fileDataUpdate.reUploadFile') }}
        <a
          href="javascript:void(0)"
          class="close-btn"
          @click="closeDialog"
        ><svg-icon icon-class="close" /></a>
      </div>
      <file-upload
        :is-re-upload="true"
        @close="closeDialog"
      />
    </div>
  </div>
</template>

<script>
import FileUpload from '../scriptExecution/fileUpload/FileUpload'
export default {
  name: 'FileReUploadDialog',
  components: {
    FileUpload
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    }
  }
}
</script>
