<template>
  <div class="full-page-dialog">
    <div class="dialog-container">
      <div class="dialog-title">
        {{ dialogTitle }}
        <a
          href="javascript:void(0)"
          class="close-btn"
          @click="closeDialog"
        ><svg-icon icon-class="close" /></a>
      </div>
      <div class="params-setting">
        <div class="dialog-body params-setting__container">
          <template v-if="modelInfo.modelGeneratedType === 'UPLOADED'">
            <div
              v-if="ioType === 'output'"
              class="params-setting__subtitle"
            >
              <span class="label">{{ $t('model.outputType') }}</span>
              {{ outputType }}
            </div>
            <div class="params-setting__warning">
              <svg-icon icon-class="data-explanation" />
              {{ $t('model.paramsSetting.argsIOReminder', {mainScriptName}) }}
            </div>
            <div class="setting-block__warning">
              <svg-icon icon-class="data-explanation" />
              {{ $t('model.upload.columnReminder') }}
            </div>
          </template>
          <draggable
            :disabled="modelInfo.modelGeneratedType === 'TRAINED'"
            v-model="tmpArgs[ioType]"
          >
            <model-column-setting-card
              v-for="(column, index) in tmpArgs[ioType]"
              :column-info="column"
              :column-list="tmpArgs[ioType]"
              :model-type="modelInfo.modelOrigin"
              :data-type-option-list="dynamicStatsTypeOptionList"
              :key="column.id"
              @remove="removeColumnCard(index, tmpArgs[ioType])"
            />
          </draggable>
          <div
            v-if="modelInfo.modelGeneratedType === 'UPLOADED'"
            class="input-block"
          >
            <div class="input-block__label">
              {{ $t('model.paramsSetting.addColumn') }}
            </div>
            <div class="input-block__value">
              <input-verify
                v-model.number.trim="itemsPerAdd"
                name="addItem"
                type="text"
              />
            </div>
            <button
              class="btn btn-m btn-outline btn-has-icon"
              @click="addNewColumnCard()"
            >
              <svg-icon
                icon-class="plus"
                class="icon"
              />{{ $t('button.add') }}
            </button>
          </div>
        </div>
        <div class="dialog-footer">
          <div class="dialog-button-block">
            <button
              class="btn btn-outline"
              @click="closeDialog"
            >
              {{ $t('button.cancel') }}
            </button>
            <button
              v-show="isArgsDifferent"
              :disabled="isProcessing"
              class="btn btn-default"
              @click="save"
            >
              <span v-if="isProcessing">
                <svg-icon
                  v-if="isProcessing"
                  icon-class="spinner"
                />
                {{ $t('button.processing') }}
              </span>
              <span v-else>{{ $t('button.save') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modifyModelInfo } from '@/API/Model'
import { statsTypeOptionList } from '@/utils/general'
import { v4 as uuidv4 } from 'uuid'
import { Message } from 'element-ui'
import draggable from 'vuedraggable'
import ModelColumnSettingCard from '../scriptExecution/components/ModelColumnSettingCard'
import InputVerify from '@/components/InputVerify'
export default {
  name: 'ParamsSettingDialog',
  inject: ['$validator'],
  components: {
    ModelColumnSettingCard,
    InputVerify,
    draggable
  },
  props: {
    modelInfo: {
      type: Object,
      default: () => ({})
    },
    ioType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isProcessing: false,
      itemsPerAdd: '1',
      mainScriptName: 'main.py',
      tmpArgs: JSON.parse(JSON.stringify(this.modelInfo.ioArgs))
    }
  },
  computed: {
    modelId () {
      return this.$route.params.model_id
    },
    dialogTitle () {
      return this.$t('model.paramsSetting.editParams', {
        IO: this.ioType
      })
    },
    dynamicStatsTypeOptionList () {
      if (this.ioType === 'input') return statsTypeOptionList
      const availableStatsTypeSet = new Set(['CATEGORY', 'NUMERIC'])
      return statsTypeOptionList.filter(statsType => availableStatsTypeSet.has(statsType.value))
    },
    isArgsDifferent () {
      return JSON.stringify(this.tmpArgs) !== JSON.stringify(this.modelInfo.ioArgs)
    },
    outputType () {
      return {
        UPLOADED_SINGLE_RECORD: this.$t('etl.column'),
        UPLOADED_MULTI_DATASET: this.$t('editing.dataFrame')
      }[this.modelInfo.modelOrigin]
    }
  },
  methods: {
    save () {
      if (!this.isArgsDifferent) return
      this.$validator.validateAll().then((isValidate) => {
        if (!isValidate) return

        this.isProcessing = true

        modifyModelInfo(this.modelId, {
          ioArgs: {
            [this.ioType]: this.tmpArgs[this.ioType]
          }
        })
          .then(() => {
            Message({
              message: this.$t('message.saveSuccess'),
              type: 'success',
              duration: 3 * 1000,
              showClose: true
            })
          })
          .finally(() => {
            this.isProcessing = false
            this.$emit('confirmBtn')
          })
      })
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    removeColumnCard (index, args) {
      args.splice(index, 1)
    },
    addNewColumnCard () {
      const emptyArray = []
      const length = this.tmpArgs[this.ioType].length
      for (let i = 0; i < this.itemsPerAdd; i++) {
        if (this.modelInfo.modelOrigin === 'UPLOADED_MULTI_DATASET') {
          emptyArray.push({
            modelDataFrameName: null,
            id: uuidv4()
          })
        } else {
          emptyArray.push({
            statsType: null,
            modelColumnName: null,
            id: uuidv4()
          })
        }
      }
      this.tmpArgs[this.ioType].splice(length, 0, ...emptyArray)
    }
  }
}
</script>

<style lang="scss" scoped>
.params-setting {
  height: 65vh;
  display: flex;
  flex-direction: column;

  &__subtitle {
    margin-bottom: 16px;
    .label {
      font-weight: 600;
      color: #CCCCCC;
    }
  }

  &__container {
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #323A3AF2;
    overflow: auto;
  }

  &__warning {
    margin-bottom: 8px;
    font-size: 13px;
    font-weight: normal;
    color: var(--color-warning);
  }

  .input-block {
    display: flex;
    align-items: center;
    &__label {
      font-size: 14px;
      font-weight: 600;
      color: #ccc;
    }
    &__value {
      margin: 0 16px;
      background-color: #252C2C;
      border-radius: 5px;
    }
    ::v-deep .input-verify .input-verify-text {
      width: 70px;
      height: 30px;
      margin-bottom: 0;
      padding: 5px 10px;
      border-bottom: none;
    }

    ::v-deep .el-input__inner {
      padding-left: 0;
      border-bottom: 1px solid #FFFFFF;
    }
  }
}
</style>
